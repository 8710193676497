.App {
  padding:0 5%;
}
.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: sans-serif;
  color: antracite;
  text-align: center;
}

button {font-size:x-large;}
input {border:1px solid grey; min-width:35vw;height:30px; font-size: calc(5px + 2vmin);}
.mailinglist {width:100%;}
#message{color:green}

